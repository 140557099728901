import React from 'react';
import { useLanguageContext } from '../contexts/language-context';

const NotFoundPage = () => {
  const { translations, activeLanguage } = useLanguageContext();

  return (
    <div className="w-full flex flex-col items-center pt-10 pb-8 px-4">
      <h1 className="mb-4 text-center text-2xl font-semibold">404</h1>
      <h1 className="text-center text-xl">
        {translations.pageNotFound?.[activeLanguage]}
      </h1>
    </div>
  );
};

export default NotFoundPage;
